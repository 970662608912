import React from 'react'
// Customizable Area Start
import PatientRegistrationController from "./PatientRegistrationController.web";
import { Modal, Form, Input, Select, Row, Col, Button, DatePicker } from "antd";
import { FiCamera } from "react-icons/fi"
import { AiOutlineUser } from "react-icons/ai"
// Customizable Area End
export class PatientRegistration extends PatientRegistrationController {

  // Customizable Area Start
  renderPatientRegisterModal = () => {
    return (
      <div className="patientRegisterContainer">
        <Modal
          centered
          open={this.state.isRegisterModal}
          footer={null}
          width={920}
          closable={false}
        >
          <p className="patientRegHeading">Sign Up Form</p>
          <div className="patientForm">
            <div className="patientRegFormRightContainer">
              <div className="uploadIPatientmageContainer">
                {this.state?.patientImage ? (
                  <img
                    src={this.state.patientImage}
                    alt=""
                    className="uploadedPatientImage"
                  />
                ) : (
                  <p>
                    <AiOutlineUser />
                  </p>
                )}
                <button data-test-id="uploadImageId" className="uploadPatientImageAction">
                  <input
                    data-test-id="imgUploadId"
                    type="file"
                    accept="image/*"
                    name="image-upload"
                    id="input"
                    style={{ display: "none" }}
                    onChange={this.imgFilehandler}
                  />
                  <label htmlFor='input' >
                    <FiCamera
                    />
                  </label>
                </button>
              </div>
            </div>
            <div className="patientRegFormLeftContainer">
              <Form
                data-test-id='submitFormId'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                onFinish={this.onFinishCreatePatient}
                className="patient-registration-style"
                fields={[
                  {
                    name: ["fullName"],
                    value: this.state.patientName,
                  },
                  {
                    name: ["PhoneNo"],
                    value: this.state.patientNumber,
                  },
                ]}
              >
                <Row gutter={[16, 8]}>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Full Name"
                      name="fullName"
                      rules={[
                        {
                          pattern: /^[a-zA-Z. ]{3,30}$/,
                          message: "It should be valid Name",
                        },
                      ]}
                    >
                      <Input data-test-id='fullNameID' name='fullName' />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Email Id"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your email id.",
                        },
                        {
                          pattern: /^\s*\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,16}\s*$/,
                          message: "It should be valid email Id",
                        },
                      ]}
                    >
                      <Input data-test-id='emailId' name='email' />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Phone No."
                      name="PhoneNo"
                      rules={[
                        {
                          pattern: /^[6-9]\d{9}$/,
                          message: "It should be valid Phone No.",
                        },
                      ]}
                    >
                      <Input data-test-id='PhoneNoId' name='PhoneNo' readOnly />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Date of Birth(YYYY-MM-DD)"
                      name="DOB"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your DOB",
                        },
                      ]}
                    >
                      <DatePicker className="createDob" data-test-id='DOBId' name='DOB' disabledDate={this.disabledPastDate} />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Gender"
                      name="Gender"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Gender",
                        },
                      ]}
                    >
                      <Select
                        data-test-id='GenderId'
                      >
                        <Select.Option value="Male">Male</Select.Option>
                        <Select.Option value="Female">Female</Select.Option>
                        <Select.Option value="Others">Others</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Weight(KG)"
                      name="Weight"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your weight",
                        },
                        {
                          pattern: new RegExp(/^(?:[1-9]\d{0,2}(\.\d{1,2})?|0\.\d{1,2})(kg|lbs)?$/),
                          message: "Please enter valid weight",
                        }
                      ]}
                    >
                      <Input data-test-id='WeightId' name='Weight' />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Blood Group"
                      name="BloodGroup"
                      rules={[
                        {
                          required: true,
                          message: "Please select your blood group",
                        },
                      ]}
                    >
                      <Select
                        data-test-id='BloodGroupId'

                      >
                        <Select.Option value="A+">A+</Select.Option>
                        <Select.Option value="A-">A-</Select.Option>
                        <Select.Option value="B+">B+</Select.Option>
                        <Select.Option value="B-">B-</Select.Option>
                        <Select.Option value="O+">O+</Select.Option>
                        <Select.Option value="O-">O-</Select.Option>
                        <Select.Option value="AB+">AB+</Select.Option>
                        <Select.Option value="AB-">AB-</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your city",
                        },
                        {
                          pattern: /^[A-Z ]+$/i,
                          message: "Please enter valid city name."
                        }
                      ]}
                    >
                      <Input data-test-id='CityId' name='city' />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Aadhar No."
                      name="AadharNo"
                      
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Aadhar Card No",
                        },
                        {
                          pattern: /^[2-9]{1}\d{3}\d{4}\d{4}$/,
                          message: "Please enter valid Aadhar card no."
                        }
                      ]}
                    >
                      <Input data-test-id='AadharNoId' placeholder='XXXXXXXXXXXX' name='AadharNo' onChange={this.handleOnChangeValues} />
                    </Form.Item>
                    {this.state.aadharTaken ? (
                      <p className="error-password">Aadhar number has already been taken</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  {/* <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Health ID"
                      name="Health"
                      rules={[
                        {
                          pattern: /^[A-Za-z0-9]*$/,
                          message: "Please enter valid Health Id."
                        }
                      ]}
                    >
                      <Input data-test-id='HealthID' name='Health' />
                    </Form.Item>
                  </Col> */}
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Ayushman Bharat ID"
                      name="AyushmanBharat"
                      rules={[
                        {
                          pattern: /^[A-Za-z0-9]*$/,
                          message: "Please enter valid Ayushman bharat Id."
                        }
                      ]}
                    >
                      <Input data-test-id='AyushmanBharatID' name='AyushmanBharat' />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} sm={24} md={24} xs={24}>
                    <Form.Item
                      label="Disease(History or Current)"
                      name="disease"
                      rules={[
                        {
                          pattern: /^[a-zA-Z\s,]+$/,
                          message: "Please enter valid disease names, separated by commas or spaces."
                        }
                      ]}
                    >
                      <Input data-test-id='diseaseId' name='disease' />
                    </Form.Item>
                  </Col>
                  <Col xl={24} lg={24} sm={24} md={24} xs={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="submitButton"
                      >
                        Continue
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <div>
        {this.renderPatientRegisterModal()}
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
export default PatientRegistration;
// Customizable Area End