import React from "react";
// Customizable Area Start
import {Typography,Avatar,Button} from "antd"
import { Link } from 'react-router-dom';
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import { CertificationComponent } from "../../../components/src/CertificationComponent.web";
import MerilLoader from '../../../components/src/MerilLoader.web';
import {searchimg } from "../../../blocks/splashscreen/src/assets";
import { Default } from "../../splashscreen/src/assets";
import {RightOutlined} from "./assets"
let config = require('../../../framework/src/config.js').baseURL;
import FooterCommonComponent from "../../../components/src/FooterDoctor.web";
import Text from "antd/lib/typography/Text";
import {Box} from "@material-ui/core"
// Customizable Area End

import PatientAppointmentController, {
  Props
} from "./PatientAppointmentController.web";



 class PatientAppointment extends PatientAppointmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNavigationTitlee = () => {

    let isAppointmentActiveOnDoctorList =
    window.location.pathname === "/patient-appointment";
  let appointmentClassName = isAppointmentActiveOnDoctorList ? "active" : "";
     
    return (
        <>
            <div className="navigationContainer">
                <Link to="/patienthome" >Home</Link>
                <Link className={appointmentClassName} to="/patient-appointment-landingpage" >Appointments</Link>
                <Link to="/services" >Services</Link>
                <Link to="/message" >Message</Link>
            </div>
        </>
    )
    }

    renderSearchDoctorDettail = () => {  
      return (
          <>
        {
          this.state.doctorDetial && (
            <div className="doctorWrapper">
            <div className="doctor-left-container" >
              <Avatar className="doctorList-avatar"  size="large" src={ this.state.doctorDetial.attributes?.profile_image ?config + this.state.doctorDetial.attributes?.profile_image:Default} />
             <div className="doctor-left-side-content" >
             <Typography className="doctorName">{this.state.doctorDetial.attributes.full_name}</Typography>
               {this.renderDoctorExperience(this.state.doctorDetial)}
              <Typography className="doctorLanguageTitle" style={{padding:"8px 0"}} >Language Speak: <span className="doctor-language">{this.state.doctorDetial.attributes.language.length>0 ?   this.handleDoctorKnowLanguage(this.state.doctorDetial.attributes.language).join(", ") : "English" } </span> </Typography>
                {this.renderDoctorConsultationFee(this.state.doctorDetial)}
            </div>
  
            </div>
            
            <div className="doctor-right-container" >
  
            <Button
            // disabled={this.handleDoctorConsultationFee(this.state.doctorDetial)}
            data-test-id="bookAppointmentID3"
             className="continueButton" 
             onClick={()=>this.handleBookAppointment(this.state.doctorDetial)}
            > Book Appointment</Button>
            </div>
  
          </div>
          )
}

          </>
      )
      }

    
   renderDoctorConsultationFee = (item: any) => {
     return (
       <>
         {
           this.handleDoctorConsultationFee(item) ? (
             <Typography className="doctorFees" >Fees: <span  >
               {
                 this.handleDoctorConsultationFee(item)
               }
             </span></Typography>
           ) : ""
         }
       </>
     )
   }

    renderDoctorExperience=(item:any)=>{
      return (
        <Typography className="doctorDescription" >{item.attributes.specialization}  {item.attributes.experience ? "| " + item.attributes.experience+ " Years Experience ": "" }</Typography>
      )
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {appointmentNavigationName} = this.state;
    return (
     <>
         <NavbarCommonComponent data-test-id="navbarCommonComponentID"  handleProfile={this.showDetailsHandler} />
         <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />

     {this.renderNavigationTitlee()}
        <Box pb={3} pt={1} > 
        <div
          className="navigation-list-css-container"
          style={{ paddingLeft: "5%" }}
        >
          <div key={"patientHome"}>
            <Text
              data-test-id="patientHomeID"
              className="navigation-list-css"
              style={{ color: "#498ECC" }}
              onClick={() => this.handleNavigationToPatientHome() }
            >
              Home
            </Text>
            <img className="rightArrowIcon" src={RightOutlined} alt=""/>
          </div>
          <div key={"ri425"}>
            <Text
              data-test-id="patientAppointmentLandingPageID"
              key={"appointments"}
              style={{ color: "#498ECC" }}
              className="navigation-list-css"
              onClick={() => this.handleNavigationToAppointmentPage()}
            >
           Appointments
            </Text>
          
            <img className="rightArrowIcon" src={RightOutlined} alt=""/>
          </div>

          <div>
            <Text style={{ color: "#353535" }} className="navigation-list-css">
              {appointmentNavigationName}
            </Text>
          </div>
        </div>
        </Box>
     <div className="doctorList" ref={this.myRef} >
     <div className='searchBoxDoc_doctor'>
        <span>
        <img src={searchimg} />
        </span> 
        <input 
        name="searchInput"
        data-test-id="doctorSearchID"
         type='text'
         className='searchInput_doctor' 
         placeholder='Search for Doctors, Specialities, Clinics'
         value={this.state.searchTerm}
         onChange={this.handleSearch} />
      </div>
      {
        this.state.searchTerm === "" ? (
          <div>
          {
            this.state.doctorList?.map((item:any)=>(
              <div className="doctorWrapper" key={item.id}  >
                <div className="doctor-left-container" >
                  <Avatar className="doctorList-avatar"  size="large" src={ item.attributes.profile_image? config + item.attributes.profile_image:Default} />
                 <div className="doctor-left-side-content" >
                 <Typography className="doctorName">{item.attributes.full_name}</Typography>
                   {this.renderDoctorExperience(item)}
                  <Typography className="doctorLanguageTitle" style={{padding:"8px 0"}} >Language Speak: <span className="doctor-language">{ (item.attributes.language && item.attributes.language.length > 0) ? this.handleDoctorKnowLanguage(item.attributes.language).join(", ") : "English"}</span> </Typography>
                  {
                  this.renderDoctorConsultationFee(item)
                  }
                </div>
      
                </div>
                
                <div className="doctor-right-container" >
                <Button
                  data-test-id="bookAppointmentID"
                  className="bookAppointmentButton"
                  onClick={()=>this.handleBookAppointment(item)}
                  disabled={!this.handleDoctorConsultationFee(item)}
                   >
                     Book Appointment
                </Button>
                </div>
      
              </div>
            ))
           }
           </div>
        ):(
          <div>
          {
            this.state.filteredDoctorList?.map((item2:any)=>(
              <div className="doctorWrapper" key={item2.id}  >
                <div className="doctor-left-container" >
                  <Avatar className="doctorList-avatar"  size="large" src={ item2.attributes.profile_image? config + item2.attributes.profile_image:Default} />
                 <div className="doctor-left-side-content" >
                 <Typography className="doctorName">{item2.attributes.full_name}</Typography>
                 {this.renderDoctorExperience(item2)}
                  <Typography className="doctorLanguageTitle" style={{padding:"8px 0"}} >Language Speak: <span className="doctor-language">{ this.handleDoctorKnowLanguage(item2.attributes.language).join(", ") ?? null } </span> </Typography>
                  {this.renderDoctorConsultationFee(item2)}
                {<div>
      
                </div>} 
                </div>
      
                </div>
                
                <div className="doctor-right-container" >
                <Button data-test-id="bookAppointmentID2"
                  className="bookAppointmentButton"
                  onClick={()=>this.handleBookAppointment(item2)}
                  disabled={!this.handleDoctorConsultationFee(item2)}
                   >
                     Book Appointment
                </Button>
                </div>
      
              </div>
            ))
           }
           </div>
        )

      }

      {
        this.renderSearchDoctorDettail()
      }

     <CertificationComponent/>
     </div>
      <FooterCommonComponent />
     
     </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default MerilLoader(PatientAppointment);
export { PatientAppointment };
// Customizable Area End
